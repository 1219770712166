import logoLink from '../resources/aerztefon.svg';

export const cssVariables = {
    active: '#D8011E',
    inactive: '#535557',

    primary_light: '#F7CCD2',
    primary: '#D8011E',
    primary_dark: '#ad0118',
    text_on_primary: '#fff',
    primary_transparent: '#F7CCD2',

    secondary: '#535557',
    secondary_light: '#E2E1DE',
    text_on_secondary: 'white',
    secondary_transparent: '#E2E1DE',

    font_color: '#2C2A29',
    link_color: '#D8011E',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#535557',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#D8011E',

    dropdown_background: '#F7CCD2',

};

export const logo = logoLink;
